#captcha_form {
    text-align:center;
}

.form-group {
    position: relative;

    .control-label + input + .btn-show-password,
    .control-label + input + .help-block + .btn-show-password {
        top: 38px;
        transform: translate(0, 0);
    }

    .btn-show-password {
        position: absolute;
        top: 50%;
        right: 5px;
        border: none;
        background-color: transparent;
        transform: translate(0, -50%);
    }
}

.has-error .help-block {
    color: red;
}
@import "custom";
@import "flag-icon.min.css";
@import "~bootstrap/scss/bootstrap";


$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$fa-font-path: "~font-awesome/fonts";
@import "~font-awesome/scss/font-awesome.scss";

@media (min-width: 768px) {
    @-webkit-keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .sf-toolbar {
        opacity: 0;
        -webkit-animation: fade-in 1s .2s forwards;
        animation: fade-in 1s .2s forwards;
    }
}

html{
    height:100%;
}

body{
    height:100%;
}